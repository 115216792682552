import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as _moment from 'moment';
import { NotificationService } from 'src/app/services/notification.services';

import { PlansService } from '../../investment-pouches/services/plans.service';
import { PouchService } from '../../investment-pouches/services/pouch.service';
import { Load } from '../../investment-pouches/store/actions/pouch.action';
import { PouchState, selectPouchState } from '../../investment-pouches/store/pouch.state';
import { PaymentLoaderComponent } from '../../navigation/payment-loader/payment-loader.component';
import { PublicService } from '../../public/services/public.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-renew-popup',
  templateUrl: './subscription-renew-popup.component.html',
  styleUrls: ['./subscription-renew-popup.component.scss'],
  providers: [DecimalPipe]
})
export class SubscriptionRenewPopupComponent implements OnInit {
  AUA: any = '';
  getDetails: any;
  getCountry: any;
  getStateName: any;
  subscriptionsList: any = [];
  selected: any;
  minimumInvestmentAmountCopy: number = 0;
  showInv_error: boolean = false;
  totalAmount: any = 0.0;
  pouches: any;
  getState: Observable<any>;
  pouchDetail: any;
  pouchId: any;
  totalReturns: number = 0;
  form!: FormGroup;
  constructor(
    private router:Router,
    private decimalPipe: DecimalPipe,
    private store: Store<PouchState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SubscriptionRenewPopupComponent>,
    private pouchService: PouchService,
    private publicService: PublicService,
    private notifyService: NotificationService,
    private dialog: MatDialog,
    private plansService: PlansService
  ) {
    this.getState = this.store.select(selectPouchState);
  }
  defaultBody: any;
  uploadedDate: any;
  pouchReturns :any
  ngOnInit(): void {

    const storageUser: any = localStorage.getItem('user');
    const user = JSON.parse(storageUser);
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.defaultBody = {
      current_date: this.uploadedDate?.split('T')[0],
      subscriber_id: user.id
    };
    this.pouchId = this.data.pouchId;

    this.pouchService.getPouchList({ pouch_id: this.pouchId }).subscribe((res: any) => {
      this.pouchReturns = this.data?.pouchReturn?.[0]?.pouch_subscriber_returns?.[0];
      const tr = res[0].pouches.map((e:any)=>e)
      this.pouches = tr;
      this.pouchDetail = tr[0];
      this.getSubscriptionPlanList();
      this.totalReturns =
        this.data?.pouchReturn?.[0]?.pouch_subscriber_returns?.[0]?.total_returns || 0;

      this.minimumInvestmentAmountCopy = Number(Math.round(this.pouchDetail?.min_amt).toFixed(2));

      this.AUA =
        Number(Math.round(Number(this.pouchReturns?.final_investment_amount || 0)).toFixed(2)) + Number(this.totalReturns)||this.data.invAmnt;
    });


    this.form = new FormGroup({
      address: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
        Validators.pattern('^[1-9][0-9]{5}$')
      ])
    });
    const data = {
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id
    };
    this.plansService.getBillingDetails(data).subscribe((res: any) => {
      this.getDetails = res[0].sub_bill_addrs;

      this.form.patchValue({
        address: this.getDetails[0].billing_address,
        country: this.getDetails[0].billing_country,
        state: this.getDetails[0].billing_state,
        city: this.getDetails[0].billing_city,
        pincode: this.getDetails[0].billing_pincode
      });
    });
    this.plansService.getCountry().subscribe((res: any) => {
      this.getCountry = res[0].country_name;
    });
    this.plansService.getState().subscribe((res: any) => {
      this.getStateName = res[0].country_name;
    });
  }
  getSubscriptionPlanList() {
    this.subscriptionsList = this.pouchDetail?.public___pouch_subscriptions[0]?.subscription_freq
      ? this.pouchDetail.public___pouch_subscriptions
      : [];

    this.selected = this.subscriptionsList[0]?.rowIndex;

  }
  getPouchName() {
    return this.pouchDetail?.heading_text;
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  allowNumber(event: any) {
    const input = event.key;
    const isDigit = /^\d$/.test(input);
    if (!isDigit) {
      event.preventDefault();
    }
  }

  onPasteAllowNumber(event: any) {
    const isAllDigits = /^\d+$/.test(event.clipboardData.getData('Text'));
    if (!isAllDigits) {
      event.preventDefault();
    }
  }

  radioChanged(event: any, ele: any) {
    this.selected = event.value;

  }
  getOptionsText(type: string, subscriptionType: string) {
    if (!(this.subscriptionsList?.length > 0)) {
      return '';
    }
    return `${this.subscriptionFreqToText(type)} : Rs. ${this.decimalPipe.transform(
      this.getamount(type, subscriptionType),
      '1.0-0'
    )}`;
  }

  getamount(type: any, subscriptionType: string) {
    if (!(this.subscriptionsList?.length > 0)) {
      return;
    }
    let getsubscriptiondata = this.subscriptionsList?.find(
      (ele: any) => ele.subscription_freq === type && ele.subscription_type === subscriptionType
    );

    if (!getsubscriptiondata) return this.AUA;

    let value =
      (this.AUA * getsubscriptiondata.subscription_perc) /
      (100 * (type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2));

    if (getsubscriptiondata.subscription_type === 'MAX') {
      value = Math.max(value, getsubscriptiondata.subscription_amount);
    } else if (getsubscriptiondata.subscription_type === 'MIN') {
      value = Math.min(value, getsubscriptiondata.subscription_amount);
    } else if (getsubscriptiondata.subscription_type === 'BOTH') {
      value += getsubscriptiondata.subscription_amount;
    }
    return Math.round(value) || '0';
  }

  subscriptionFreqToText(type: string): string {
    if (type === 'MONTHLY') {
      return 'Monthly subscription';
    } else if (type === 'QUARTERLY') {
      return '3 months subscription';
    } else {
      return '6 months subscription';
    }
  }
  getFrequencyPlan() {
    if (!(this.subscriptionsList?.length > 0)) {
      return;
    }
    let value = '';
    switch (
      this.subscriptionsList.find((e: any) => e.rowIndex == this.selected).subscription_freq
    ) {
      case 'MONTHLY':
        value = '1 month';

        break;
      case 'QUARTERLY':
        value = '3 months';

        break;
      case 'HALF-YEARLY':
        value = '6 months';

        break;
    }
    return value;
  }

  renewSubscription(ele: any) {

    this.form.markAllAsTouched();
    if (!ele) {
      this.showInv_error = true;
      return;
    } else {
      let stateCode = this.getStateName.find(
        (e: any) => e.state_name === this.form.controls['state'].value
      ).state_code;
      const data = {
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')?.id,
        docs: {
          billing_address: this.form.controls['address'].value?.replace(/\n+/g, ' ')?.replace(/\s+/g, ' ')?.trim(),
          billing_country: this.form.controls['country'].value,
          billing_state: this.form.controls['state'].value,
          billing_city: this.form.controls['city'].value,
          billing_pincode: this.form.controls['pincode'].value,
          billing_state_code: stateCode
        }
      };
      if (this.form.valid) {
        this.plansService.updateBillingDetails(data).subscribe((res: any) => {
          if (!this.data.agreementSigned) {
            this.goAgreementSiginig(data.docs)
            return
          }
          this.renewPayment(data.docs);
        });
      }
      // this.renewPayment();
    }
  }
  goAgreementSiginig(billing: any) {
    let payLoad = {
      investment_amount: String(Math.round(this.AUA)),
      pouch_id: this.pouchId,
      frequency: this.subscriptionsList.find((e: any) => e.rowIndex == this.selected)
        .subscription_freq,
      subscription_amount: this.getamount(
        this.subscriptionsList.find((e: any) => e.rowIndex == this.selected).subscription_freq,
        this.subscriptionsList.find((e: any) => e.rowIndex == this.selected).subscription_type
      ),
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')?.id,
      redirect_url: `${window.location.host}/investment/map-subscriber/${this.pouchId}`
    };
    const getStartDate = () => {
      let currentExpiryDate = _moment(
        this.subscriptionStatus?.[0].public___pouch_subscriber_subscriptions?.[0].end_date
      );
      let today = _moment(new Date());
      return currentExpiryDate.add(1, 'days').format('YYYY-MM-DD');
      // return currentExpiryDate.isAfter(today)
      //   ? currentExpiryDate.add(1, 'days').format('YYYY-MM-DD')
      //   : today.format('YYYY-MM-DD');
    };

    const getEndDate = (startDate: string, freq: any) => {
      const dt = _moment(startDate);

      switch (freq) {
        case 'QUARTERLY':
          dt.add(3, 'months');
          break;
        case 'MONTHLY':
          dt.add(1, 'months');
          break;
        case 'YEARLY':
          dt.add(1, 'years');
          break;
        case 'HALF-YEARLY':
          dt.add(6, 'months');
          break;
        default:
          return '';
      }

      return dt.format('YYYY-MM-DD');
    };
    let body: any = {
      subscriberObj: [
        {
          pouch_id: this.pouchDetail.id,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
          subscription_type: this.selected,
          start_date: getStartDate(),
          end_date: getEndDate(getStartDate(), payLoad.frequency),
          stoploss_stock: 0,
          stoploss_portfolio: 0,
          investment_amount:  this.AUA ||this.data.invAmnt,
          is_active: 'Y'
        }
      ],
      docsi: [
        {
          pouch_id: this.pouchDetail.id,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
          investment_amount: this.AUA ||this.data.invAmnt,
          change_amount: this.AUA ||this.data.invAmnt,
          from_date: getStartDate()
        }
      ],
      pouch_id: this.pouchDetail.id,
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      review_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],

      investment_amount: this.data.invAmnt,
      minimum_investment: this.pouchDetail.min_amt
    };

      this.publicService.agreementFrom = 'renew';
      this.publicService.mapSubscriberData.data = body;
      this.publicService.mapSubscriberData.agreementDetails = this.pouchDetail;
    this.publicService.mapSubscriberData.subscription = {
      aua: this.AUA,
      pouch_id: payLoad.pouch_id,
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      subscription_type: this.subscriptionsList[0]?.subscription_type,
      subscription_perc: this.subscriptionsList[0]?.subscription_perc,
      subscription_amount: this.subscriptionsList[0]?.subscription_amount,
      subscription_amount_final: this.getamount(
        this.subscriptionsList.find((e: any) => e.rowIndex == this.selected).subscription_freq,
        this.subscriptionsList.find((e: any) => e.rowIndex == this.selected).subscription_type
      ),
      discount_amount: 0,
      promo_code: null,
      subscription_freq: payLoad.frequency,
      start_date: getStartDate(),
      end_date: getEndDate(getStartDate(), payLoad.frequency),
      order_id: '',
      bank_ref_no: '',
      investment_amount: body.investment_amount,
      ...billing
    };
   
    
    this.router.navigateByUrl('/agreement');
    this.dialogRef.close()

 }


  subscriptionStatus: any;
  renewPayment(billing?: any) {
    this.checkSubscriptionStatus();
    let payLoad = {
      investment_amount: String(Math.round(this.AUA)),
      pouch_id: this.pouchId,
      frequency: this.subscriptionsList.find((e: any) => e.rowIndex == this.selected)
        .subscription_freq,
      subscription_amount: this.getamount(
        this.subscriptionsList.find((e: any) => e.rowIndex == this.selected).subscription_freq,
        this.subscriptionsList.find((e: any) => e.rowIndex == this.selected).subscription_type
      ),
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')?.id,
      redirect_url: `${window.location.host}/investment/map-subscriber/${this.pouchId}`
    };
    this.publicService.intiatePaymentGateway(payLoad).subscribe(
      (res1: any) => {
        this.redirectToPayment(res1, payLoad, billing);
      },
      (e) => {
        this.notifyService.showError('', e.error.error);
      }
    );
  }
  private _destroy$ = new Subject<void>();
  checkSubscriptionStatus() {
    this.pouchService
      .get_pouch_subscriber_status({
        pouch_id: this.pouchId,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        if (res && res.length > 1 && res[1]?.subscription_status) {
          this.subscriptionStatus = res[1]?.subscription_status;
        }
      });
  }
  redirectToPayment(data: any, payLoad: any, billing?: any) {
    console.clear();


    const getStartDate = () => {
      let currentExpiryDate = _moment(
        this.subscriptionStatus?.[0].public___pouch_subscriber_subscriptions?.[0].end_date
      );
      let today = _moment(new Date());
      return currentExpiryDate.add(1, 'days').format('YYYY-MM-DD');
      // return currentExpiryDate.isAfter(today)
      //   ? currentExpiryDate.add(1, 'days').format('YYYY-MM-DD')
      //   : today.format('YYYY-MM-DD');
    };

    const getEndDate = (startDate: string, freq: any) => {
      const dt = _moment(startDate);

      switch (freq) {
        case 'QUARTERLY':
          dt.add(3, 'months');
          break;
        case 'MONTHLY':
          dt.add(1, 'months');
          break;
        case 'YEARLY':
          dt.add(1, 'years');
          break;
        case 'HALF-YEARLY':
          dt.add(6, 'months');
          break;
        default:
          return '';
      }

      return dt.format('YYYY-MM-DD');
    };

    this.publicService.mapSubscriberData.returnToComposition = true;
    let body = {
      isRenew: true,
      subscriberObj: [
        {
          pouch_id: payLoad.pouch_id,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
          subscription_type: payLoad.frequency,
          start_date: getStartDate(),
          end_date: getEndDate(getStartDate(), payLoad.frequency),
          stoploss_stock: 0,
          stoploss_portfolio: 0,
          investment_amount: this.pouchReturns?.final_investment_amount ||this.AUA|| this.data.invAmnt,
          is_active: 'Y'
        }
      ],
      docsi: [
        {
          pouch_id: payLoad.pouch_id,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
          investment_amount: this.pouchReturns?.final_investment_amount || this.AUA || this.data.invAmnt,
          change_amount: this.pouchReturns?.final_investment_amount || this.AUA || this.data.invAmnt,//this.subscriptionStatus[0].investment_amount,
          from_date: getStartDate()
        }
      ],
      pouch_id: payLoad.pouch_id,
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      review_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
      end_date: getEndDate(getStartDate(), payLoad.frequency),
      investment_amount: this.pouchReturns?.final_investment_amount || this.AUA || this.data.invAmnt,
      minimum_investment: this.pouchDetail.min_amt
    };

    this.publicService.agreementFrom = 'renew';
    this.publicService.mapSubscriberData.data = body;
    this.publicService.mapSubscriberData.agreementDetails = this.pouches;
    this.publicService.mapSubscriberData.subscription = {
      aua: this.AUA,
      pouch_id: payLoad.pouch_id,
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      subscription_type: this.subscriptionsList[0]?.subscription_type,
      subscription_perc: this.subscriptionsList[0]?.subscription_perc,
      subscription_amount: this.subscriptionsList[0]?.subscription_amount,
      subscription_amount_final: this.getamount(
        this.subscriptionsList.find((e: any) => e.rowIndex == this.selected).subscription_freq,
        this.subscriptionsList.find((e: any) => e.rowIndex == this.selected).subscription_type
      ),
      discount_amount: 0,
      promo_code: null,
      subscription_freq: payLoad.frequency,
      start_date: getStartDate(),
      end_date: getEndDate(getStartDate(), payLoad.frequency),
      order_id: '',
      bank_ref_no: '',
      investment_amount: body.investment_amount,
      ...billing
    };

    sessionStorage.setItem('pouch', JSON.stringify(this.publicService.mapSubscriberData));
debugger
    this.dialog.open(PaymentLoaderComponent, {
      data: { ...data, isRenew: true },
      panelClass: 'custom-modalbox'
    });
  }
  getSelectedSubscriptionFreq() {
    const found = this.subscriptionsList.find((e: any) => e.rowIndex == this.selected);
    return found ? found.subscription_freq : null;
  }
  getSelectedSubscriptionType() {
    const found = this.subscriptionsList.find((e: any) => e.rowIndex == this.selected);
    return found ? found.subscription_type : null;
  }
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
