<ng-container>
  <div class="subscriber-component ui-update">
    <div class="container">
      <div class="container-lg white-bg public-bg-wrapper mt-40 mb-60 pd-40">
        <form #myForm method="post" [action]="paymentUrl" ngNoForm hidden="true">
          <input name="encRequest" [(ngModel)]="paymentData.encRequest" />
          <input name="access_code" [(ngModel)]="paymentData.access_code" />
          <button type="submit"></button>
        </form>
        <ng-container *ngIf="pouchDetail && refresh">
          <app-banner-pouch-detail
            [tabIndex]="0"
            [pouchDetail]="pouchDetail"
            *ngIf="pouchDetail && refresh">
          </app-banner-pouch-detail>
          <!--  -->
          <div class="row mt-20">
            <p
              *ngIf="pouchDetail.stop_sub && pouchDetail.subscribed == 'Y'"
              class="fw-400 text_h disclaimer"
              style="color: red !important; font-size: 13px">
              <strong>Disclaimer:</strong> This pouch has been discontinued and we are no longer
              accepting renewals for the same.
            </p>
            <div
              class="col-xl-12 col-md-12 text-center button-style dicstyle"
              style="padding: 0 !important"
              [ngClass]="
                logginUserRole === 'subscriber' &&
                pouchDetail.stop_sub &&
                pouchDetail.subscribed == 'Y'
                  ? 'dicstyle'
                  : 'removeClass'
              ">
              <ng-container *ngIf="logginUserRole === 'subscriber'">
                <div class="subscriber-data position-change">
                  <ng-container >
                    <app-banner-subscription-details
                    *ngIf="pouchDetail && refresh "
                      #bannerBtn
                      [is_pending_stoct]="is_pending_stoct"
                      [since]="since"
                      [pouchId]="pouchDetail.id"
                      (updateTabeleHandler)="updateTable($event)"
                      (updateSubscriberListHandler)="updateSubscriberList()"
                      [pouchDetail]="pouchDetail"
                      [tabIndex]="tabIndex"
                      (tabIndexChanger)="tabIndex = $event"
                      [byPassToPayment]="byPassToPayment.status"
                      (bypassToPaymentHandler)="bypass($event)"
                      [calculateSubFee]="calculateSubFee"
                      [subscriptionStatus]="subscriptionStatus"
                      [inputBannerType]="subscribtionBannerType"
                      [PouchSubscriberStocksReturns]="PouchSubscriberStocksReturns"
                      [holdStockLength]="holdStocksLength"
                      [investmentChange]="investmentChange"
                      [agreementStatus]="agreementStatus">
                    </app-banner-subscription-details>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="logginUserRole !== 'subscriber'">
                <div
                  [ngClass]="logginUserRole !== 'subscriber' ? 'addmargintop' : 'removeMargintop'"
                  class="subscriber-btn-grpup sm-mt-10 sm-mb-20">
                  <div class="row" style="margin: 0px 0 20px 0" *ngIf="logginUserRole === 'admin'">
                    <div class="col-md-12">
                      <ul
                        style="
                          padding: 1%;
                          padding-left: 0;
                          background-color: #f8f9fa;
                          display: flex;
                          margin-bottom: 0px;
                        "
                        class="text-start px-3 row"
                        [ngStyle]="{
                          opacity: logginUserRole === 'admin' ? 1 : 0,
                          pointer: logginUserRole === 'admin' ? 'none' : 'auto'
                        }">
                        <li class="col-xl-3 col-lg-3 col-md-6">
                          <div>
                            <div>
                              <span class="f-12 fw-400 text_h font-title-color">RT</span>
                              :
                              <span class="f-14 fw-400 --black3">
                                {{ pouchDetail?.rank_tolerance || '-' }}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li class="col-xl-3 col-lg-3 col-md-6">
                          <div>
                            <div>
                              <span class="f-12 fw-400 text_h font-title-color"
                                >Timeframe Period</span
                              >
                              :
                              <span class="f-14 fw-400 --black3">{{
                                pouchDetail?.lookback_periods || '-'
                              }}</span>
                            </div>
                          </div>
                        </li>
                        <li *ngIf="logginUserRole === 'admin'" class="col-xl-3 col-lg-3 col-md-6">
                          <div>
                            <div>
                              <span class="f-12 fw-400 text_h font-title-color">Reset Date</span>
                              :
                              <span class="f-14 fw-400 --black3">{{
                                pouchDetail?.reset_date | date: 'dd MMM yyyy' || '-'
                              }}</span>
                            </div>
                          </div>
                        </li>
                        <li *ngIf="logginUserRole === 'admin'" class="col-xl-3 col-lg-3 col-md-6">
                          <div>
                            <div>
                              <span class="f-12 fw-400 text_h font-title-color">Reset Years</span>
                              :
                              <span class="f-14 fw-400 --black3">{{
                                pouchDetail?.reset_years || '-'
                              }}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row custom-btn">
                    <div
                      class="col-md-3 d-flex justify-content-between change-flex-direction align-items-end">
                      <button class="theme-btn primary-btn mb-10" (click)="mapSubscriberpopup()">
                        Map Subscriber
                      </button>
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu"
                        class="theme-btn border-btn">
                        <span class="f-14"
                          >Other Actions<img
                            _ngcontent-qsw-c89=""
                            src="assets/images/icons/down-arrow.svg"
                            alt=""
                            srcset=""
                            style="padding-left: 14px"
                        /></span>
                      </button>
                    </div>
                  </div>
                  <div
                    *ngIf="logginUserRole === 'admin'"
                    class="col-6 col-md-6 pouch-details_inner"></div>

                  <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                      <span (click)="editPouch()">Edit Pouch</span>
                    </button>
                    <mat-divider></mat-divider>
                    <!-- <button mat-menu-item>
                      <span>Liquidate Pouch</span>
                    </button> -->
                    <button mat-menu-item (click)="gotoPouchVisibility()">
                      <span>Pouch Visibility</span>
                    </button>
                    <button
                      mat-menu-item
                      *ngIf="pouchDetail && showDelete(pouchDetail)"
                      (click)="deletePouch(pouchDetail)">
                      <span>Delete Pouch</span>
                    </button>
                  </mat-menu>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div class="tab-container mt-20">
          <div *ngIf="logginUserRole !== 'subscriber'" style="margin-top: -84px"></div>
          <div class="align-items-center radio-dropdown-group mb-20" style="position: relative">
            <mat-radio-group
              [(ngModel)]="radioSelected"
              aria-label="Select an option"
              (change)="valueChange($event)">
              <mat-radio-button [disabled]="disabled" [value]="0">
                <span class="fw-400 f-14">{{
                  logginUserRole !== 'subscriber' ? 'Subscribers' : 'My Subscription'
                }}</span>
              </mat-radio-button>
              <mat-radio-button class="px-3" [value]="1"
                ><span class="fw-400 f-14">Pouch</span></mat-radio-button
              >
            </mat-radio-group>
            <div class="showing col-md-8 row d-flex align-items-center">
              <div class="col-6 col-md-3 auto-width f-14">Showing upto</div>
              <div class="col-6 col-md-4 sm-pl-0 sm-pr-0">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    placeholder=""
                    [max]="currentDate"
                    [(ngModel)]="since"
                    (dateChange)="sinceDateChangeHandler($event)" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="pointer col-6 col-md-3" *ngIf="logginUserRole === 'admin'">
                <button class="download_link_btn" [matMenuTriggerFor]="dmenu">
                  <img src="../../../../assets/images/icons/file-download.svg" alt="" srcset="" />
                  <span class="download_link_text"> Download Report</span>
                </button>
                <mat-menu #dmenu="matMenu" yPosition="below">
                  <button mat-menu-item (click)="downloadCSV('rebalance')">
                    <span> Rebalance Details</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="downloadCSV('return')">
                    <span>Return Details</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="downloadCSV('summary')">
                    <span>Return Summary</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
          <div class="d-flex row sm-column-reverse">
            <div class="col-xl-12 col-md-12 pr-initial" style="padding-right: 50px">
              <mat-tab-group (selectedTabChange)="tabChanged($event)" [selectedIndex]="tabIndex">
                <mat-tab>
                  <ng-template mat-tab-label>
                    <p>Performance</p>
                  </ng-template>

                  <ng-template matTabContent>
                    <ng-container *ngIf="refresh && pouchDetail">
                      <app-performance
                        *ngIf="refresh && tabIndex == 0"
                        [subList]="subscriberList"
                        class="performance"
                        #performance
                        [since]="since"
                        [radioSelected]="radioSelected"
                        [userType]="logginUserRole"
                        [pouchDetail]="pouchDetail"
                        [pouchId]="pouchDetail.id"
                        [startDate]="pouchDetail.start_date"
                        [PouchSubscriberStocksReturns]="PouchSubscriberStocksReturns"
                        (subscriberChange)="subscriberChangeEvent($event)"
                        (radioChange)="radioChangeSubscriber($event)">
                      </app-performance>
                    </ng-container>
                  </ng-template>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>
                    <P>Pouch Composition</P>
                  </ng-template>
                  <ng-template matTabContent>
                    <ng-container
                      *ngIf="
                        (this.userSubscribed && tabIndex == 1 && !pouchExpired) ||
                        radioSelected == 0
                      ">
                      <app-pouch-composition
                      [subscriptionStatus]="subscriptionStatus"
                        *ngIf="refresh && pouchDetail && tabIndex == 1"
                        [subList]="subscriberList"
                        #composition
                        [since]="since"
                        [radioSelected]="radioSelected"
                        [userType]="logginUserRole"
                        [agreementStatus]="agreementStatus"
                        [pouchDetail]="pouchDetail"
                        [pouchId]="pouchDetail.id"
                        [pouchExpired]="pouchExpired"
                        [showAmountOnSubscriber]="showAmountOnSubscriber"

                        (holdStockLength)="holdStockLength($event)"
                        (radioChange)="radioChangeSubscriber($event)">
                      </app-pouch-composition>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        (!this.userSubscribed && tabIndex == 1) ||
                        (pouchExpired && radioSelected != 0)
                      ">
                      <div>
                        <div style="filter: blur(10px); pointer-events: none">
                          <app-pouch-composition
                          [subscriptionStatus]="subscriptionStatus"
                            *ngIf="refresh && pouchDetail && tabIndex == 1"
                            [userType]="logginUserRole"
                            [agreementStatus]="agreementStatus"
                            [pouchDetail]="pouchDetail"
                            [since]="since"
                            [radioSelected]="radioSelected"
                            [pouchId]="pouchDetail.id"
                            (subscriberChange)="subscriberChangeEvent($event)"
                            [showAmountOnSubscriber]="showAmountOnSubscriber"
                            (holdStockLength)="holdStockLength($event)">
                          </app-pouch-composition>
                        </div>
                        <ng-container *ngIf="pouchExpired">
                          <div class="popup">
                            <div style="margin-top: 40px; margin-bottom: 20px">
                              <img src="../../../../../assets/images/icons/fi-rr-lock.svg" alt="" />
                            </div>

                            <p>
                              Your subscription has expired on
                              {{ pochExpiredDate | date: 'dd MMM yyyy' }}
                              <br /><span
                                *ngIf="!pouchDetail.stop_sub && pouchDetail.subscribed == 'Y'"
                                >click here to

                                <span
                                  class="f-blue"
                                  style="cursor: pointer"
                                  (click)="renewSubscription()"
                                  >renew</span
                                ></span
                              >
                            </p>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="byPassToPayment.status === true && !pouchExpired">
                          <div class="popup">
                            <div style="margin-top: 40px; margin-bottom: 20px">
                              <img src="../../../../../assets/images/icons/fi-rr-lock.svg" alt="" />
                            </div>
                            <p>
                              Your contract was successfully signed on <br />
                              {{
                                this.byPassToPayment?.data[0]?.agreement_status[0]
                                  ?.public___subscriber_investments[0]?.registration_date
                                  | date: 'dd MMM yyyy'
                              }}. <br />

                              <span class="f-blue" style="cursor: pointer" (click)="goSubscribe1()"
                                >Subscribe</span
                              >
                              now and invest
                            </p>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="byPassToPayment.status === false" && !pouchExpired>
                          <div class="popup">
                            <div style="margin-top: 40px; margin-bottom: 20px">
                              <img src="../../../../../assets/images/icons/fi-rr-lock.svg" alt="" />
                            </div>
                            <p>
                              Please
                              <span class="f-blue" style="cursor: pointer" (click)="goSubscribe()"
                                >esign a contract</span
                              >
                              and then subscribe <br />
                              to the pouch to invest
                            </p>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-template>
                </mat-tab>

                <mat-tab *ngIf="logginUserRole !== 'subscriber'">
                  <ng-template mat-tab-label>
                    <p>Subscriber</p>
                  </ng-template>
                  <ng-template matTabContent>
                    <app-subscriber
                      *ngIf="pouchDetail && tabIndex == 2"
                      [pouchDetail]="pouchDetail"></app-subscriber>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
