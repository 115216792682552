import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable, forkJoin } from 'rxjs';

import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { PouchState, selectPouchState } from 'src/app/modules/investment-pouches/store/pouch.state';
import { NotificationService } from 'src/app/services/notification.services';
import { environment } from 'src/environments/environment';

import { PublicService } from '../../services/public.service';
import { PublicFacadeService } from '../../state/public.facade.services';
import { CreatePouchPopupComponent } from '../create-pouch-popup/create-pouch-popup.component';
import { SharedService } from './../../../../common/services/shared.service';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';

@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.scss']
})
export class DashboardDetailsComponent implements OnInit {
  @Input() pouches: any;
  dashboardHudData: any = {
    stockUploadDate: '',
    totalPouches: '',
    totalInvestments: '',
    totalReturns: '',
    topGainers: [],
    topLosers: []
  };
  yourPouch: any = [];
  riskPouches: any = [];
  returnPouches: any = [];
  loggedinUser: any;
  returns: any = [];
  tolerance: any = [];
  date: any;
  noData: boolean = false;
  getState: Observable<any>;
  constructor(
    private store: Store<PouchState>,
    public dialog: MatDialog,
    private router: Router,
    public publicService: PublicService,
    public dashboardService: DashboardService,
    public facadeService: PublicFacadeService,
    public sharedService: SharedService,
    public authService: AuthService,
    private notifyService: NotificationService,
    private pouchService: PouchService,
  ) {
    this.getState = this.store.select(selectPouchState);
    let storage: any = localStorage.getItem('user');
    this.userInfo = JSON.parse(storage).attributes;

  }
  ngOnInit(): void {
    this.date = localStorage.getItem('stock_price_uploaded_date')?.split('T')[0];
    const storageUser: any = localStorage.getItem('user');
    const user = JSON.parse(storageUser);
    this.loggedinUser = user?.attributes;
    this.authService.onUserUpdate.subscribe((user: any) => {
      this.loggedinUser = user.attributes;
      if (!this.date) {
        this.facadeService.setUploadDateToStore();
      }
    });

    this.load();
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    if (subdomain === 'wealthy') {
      this.checkwealthyStatus()
    } else if (subdomain === 'bajajbroking') {
      this.checkBajajStatus()
    }
  }
  checkwealthyStatus() {
    let body = {
      "subscriber_id": JSON.parse(localStorage.getItem('user') || '{}').id,
      from_date: new Date(
        new Date(
          localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
        ).setDate(
          new Date(
            localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
          ).getDate() - 7
        )
      )
        .toISOString()
        .split('T')[0],
      to_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
    }
    this.pouchService.getOrderDetails(body).subscribe((res: any) => {
    }, (e) => {
      this.notifyService.showError('', e.error.error.message);
    });
  }
  checkBajajStatus() {

    let body = {
      "subscriber_id": JSON.parse(localStorage.getItem('user') || '{}').id,
      from_date: new Date(
        new Date(
          localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
        ).setDate(
          new Date(
            localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
          ).getDate() - 7
        )
      )
        .toISOString()
        .split('T')[0],
      to_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
    }
    this.pouchService.getOrderDetailsBajaj(body).subscribe((res: any) => {
    }, (e) => {
      this.notifyService.showError('', e.error.error.message);
    });
  }
  load() {
    this.dashboardService.getTopGainersLosers().subscribe((kpis: any) => {
      this.dashboardHudData.topGainers = kpis[0].gainers;
      this.dashboardHudData.topLosers = kpis[1].losers;
    });

    this.dashboardService.getKPIs().subscribe(
      (hudDetails: any) => {
        this.dashboardHudData.totalPouches = hudDetails[2]?.kpi3?.[0]['mypouches'];
        this.dashboardHudData.totalInvestments = hudDetails[2].kpi3[0]['total_investments'];
        this.dashboardHudData.totalReturns = hudDetails[3].kpi4[0]['total_returns'];
        this.date = hudDetails[4].kpi5[0].last_uploaded_on?.split('T')[0];
        localStorage.setItem('stock_price_uploaded_date', hudDetails[4].kpi5[0].last_uploaded_on);
      },
      () => { },
      () => {
        this.publicService
          .getPouchListByReturnOrRiskProfile({
            limit: 6,
            sort: -21,
            current_date: this.date,
            risk_profile: [this.loggedinUser.risk_profile || '']
          })
          .subscribe((riskPoches: any) => {
            this.tolerance = riskPoches[0].pouches.map((e: any) => ({
              ...e,
              return_perc: (e.return_perc * 100).toFixed(2)
            }));
          });
        this.publicService
          .getPouchListByReturnOrRiskProfile({
            limit: 6,
            sort: -21,
            current_date: this.date
          })
          .subscribe((returnPouches: any) => {
            this.returns = returnPouches[0].pouches.map((e: any) => ({
              ...e,
              return_perc: (e.return_perc * 100).toFixed(2)
            }));
          });

        this.publicService
          .getMyPouchList({ current_date: this.date, limit: 3 })
          .subscribe((pouchList: any) => {
            this.yourPouch = pouchList?.[0]?.mypouches || [];
          });
      }
    );
  }
  userInfo: any;
  create() {
    // const dialogRef = this.dialog.open(CreatePouchPopupComponent);
    // dialogRef.afterClosed().subscribe((res: string) => {
    //   if (res === 'subscribe') {
    //     // this.router.navigateByUrl('/investment');
    //     // go to payment page
    //   }
    //   if (res === 'try') {
    //     this.publicService.userSubscribed = false;
    //     this.router.navigateByUrl('/public/pouch-creation');
    //     // go to payment page
    //   }
    // });
    let storage: any = localStorage.getItem('user');
    this.userInfo = JSON.parse(storage).attributes;
    let data: any = [];
    if (this.userInfo.createpouch == true) {
      this.router.navigateByUrl('/pouch-creation');

      return;
    }
    this.publicService.getSubscriptionList().subscribe(
      (r: any) => {
        data = r[0].subscriptions;
      },
      (e: any) => {
        this.notifyService.showError('', e.error.error.message);
      },
      () => {
        let dialog = this.dialog.open(CreatePouchPopupComponent, {
          data: { data: data, from: 'button' }
        });
        dialog.afterClosed().subscribe((val: string) => {
          if (val === 'subscribe') {
            this.router.navigateByUrl('/agreement');
          } else if (val === 'try') {
            this.router.navigateByUrl('/pouch-creation');
          } else if (val === 'subscriber') {
            this.router.navigateByUrl('/agreement-subscribe');
          } else {
            this.router.navigateByUrl('/investment');
          }
        });
      }
    );
  }
  subscribe() {
    this.router.navigateByUrl('/investment');
  }

  goToPouch(id: any) {
    this.router.navigateByUrl(`/investment/map-subscriber/${id}`);
  }
  goTo(v: any) {
    v === 0 ? this.router.navigateByUrl('/investment') : this.router.navigateByUrl('/portfolio');
  }
  filterPouches(pouchArr: any) {
    let user: any = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
    }
    if (user?.attributes?.role === 'admin') {
      return pouchArr;
    }
    pouchArr = pouchArr.filter(
      (pouch: any) => !pouch.stop_sub || (pouch.subscribed === 'Y' && pouch.stop_sub)
    );
    return pouchArr;
  }
}
